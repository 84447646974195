body,
* {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#root {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f2f5;
}

html,
body {
  padding: 0;
  margin: 0;
  overflow: hidden;
}
/* reset */
/*:lang(ko){word-break:keep-all;}*/
* {
  margin: 0;
  padding: 0;
}
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
html,
body {
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  -webkit-text-size-adjust: 100%;
}
.blind {
  display: none !important;
}
body {
  width: 100%;
  font-size: 12px;
  color: #333;
}
html *,
body * {
  font-family: 'Pretendard', sans-serif;
}
div,
p,
ol,
ul,
li,
img,
a,
h1,
h2,
h3,
form,
span,
iframe,
dl,
dt,
dd {
}
dl,
ul,
ol {
  list-style: none;
}
table {
  empty-cells: show;
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}
fieldset {
  border: none;
}
legend,
caption,
hr {
  display: none;
}
img {
  border: none;
}
img,
label {
  vertical-align: top;
}
button {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  background: none;
  -webkit-appearance: none;
}
button:focus {
  outline: none;
}
input,
select,
textarea {
  border-radius: 0;
  font-family: 'Pretendard', sans-serif;
  color: #3e4449;
}
textarea {
  resize: vertical;
}
ul,
ol {
  list-style: none;
}
em {
  font-style: normal;
}
caption span,
legend {
  overflow: hidden;
  position: absolute;
  left: -9999px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}
/* text common */
/* default link */
a {
  text-decoration: none;
  cursor: pointer;
}
a:link,
a:visited {
  text-decoration: none;
  color: #333;
  cursor: pointer;
}
a:hover,
a:active {
  text-decoration: none;
  color: #333;
  cursor: pointer;
}

/* IE10 input type auto x delete */
input[type='text']::-ms-clear,
input[type='file']::-ms-clear {
  display: none;
}

/* webkit */
::-webkit-input-placeholder {
  color: rgba(43, 42, 82, 0.3) !important;
}
/* FF 4~18 */
:-moz-placeholder {
  color: rgba(43, 42, 82, 0.3) !important;
}
/* FF 19+ */
::-moz-placeholder {
  color: rgba(43, 42, 82, 0.3) !important;
}
/* IE10+ */
:-ms-input-placeholder {
  color: rgba(43, 42, 82, 0.3) !important;
}
/* placehoder color style */
.my-placeholder {
  color: rgba(43, 42, 82, 0.3) !important;
}

/* from (error)placeholder */
.frmError {
  border-color: #f44949 !important;
}
.frmError::-webkit-input-placeholder {
  color: #f44949 !important;
}
.frmError:-moz-placeholder {
  color: #f44949 !important;
}
.frmError::-moz-placeholder {
  color: #f44949 !important;
}
.frmError:-ms-input-placeholder {
  color: #f44949 !important;
}

/* Chrome Field Outline = None */
input:focus {
  outline: none;
}
textarea:focus {
  outline: none;
}
select:focus {
  outline: none;
}
button:focus {
  outline: none;
}
/* scroll */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  background-color: rgba(195, 195, 206, 0.1);
  border-radius: 5px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #c3c3ce;
  border-radius: 5px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b5b5c3;
  border-radius: 5px;
}
.wrap {
  position: relative;
  margin: 0;
  padding: 0;
  background: #fff;
}
.full_cnt {
  margin: 40px;
}
.frm_cnt_wrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 20px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 16px 40px 0 rgba(65, 62, 73, 0.3);
  padding: 50px 0;
}
.frm_cnt_wrap .flex0 {
  width: 320px !important;
}
.frm_cnt_wrap .flex1 {
  flex: 1;
}
.frm_cnt_wrap .frm_cnt_inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 0 10px;
  overflow: overlay;
}
.frm_cnt_wrap .frm_cnt_inner .form_header {
  margin: 0 0 40px;
}
.frm_cnt_wrap .frm_cnt_inner .form_header h3 {
  margin: 0;
  padding: 0;
  font-weight: 700;
  font-size: 32px;
  line-height: 49px;
  color: #2b2a52;
}
.frm_cnt_wrap .frm_cnt_inner .desc {
  margin: 0 0 30px;
}
.frm_cnt_wrap .frm_cnt_inner .desc p {
  padding: 3px 0 1px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #2b2a52;
  text-align: center;
}
/* form_wrap */
.form_wrap {
  position: relative;
  margin: 0 auto;
  padding: 0 20px;
}
.form_wrap .frm_grp {
  position: relative;
  margin: 0 0 20px;
}
.form_wrap.frm_register .frm_grp label {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.form_wrap .frm_grp label {
  display: block;
  font-weight: 400;
  font-size: 15px;
  color: #2b2a52;
}
.form_wrap .frm_grp .frm_control {
  position: relative;
}
.form_wrap .frm_grp .frm_control input {
  width: 278px;
  height: 42px;
  padding: 0 15px;
  font-size: 15px;
  line-height: 42px;
  color: #2b2a52;
  border-radius: 10px;
  border: 1px solid #e5e5eb;
}
.form_wrap .frm_grp .frm_control textarea {
  width: 100%;
  padding: 10px 15px;
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  color: #2b2a52;
  border-radius: 10px;
  border: 1px solid #e5e5eb;
  resize: none;
}
.form_wrap .frm_grp .frm_control input:focus {
  border-color: #5c59c3;
}
.form_wrap .frm_grp .frm_control textarea:focus {
  border-color: #5c59c3;
}
.form_wrap .frm_grp .error_message {
  display: none;
  margin-bottom: -14px;
}
.form_wrap .frm_grp .error_message p {
  margin: 4px 0 0;
  font-weight: 400;
  font-size: 12px;
  color: #ff2a8e;
  line-height: 16px;
  z-index: 1000;
}
.form_wrap .frm_grp.error .frm_control input {
  color: #ff2a8e;
  border-color: #ff2a8e;
}
.form_wrap .frm_grp.error .frm_control input:focus {
  color: #2b2a52;
}
.form_wrap .frm_grp.error .frm_control textarea {
  color: #ff2a8e;
  border-color: #ff2a8e;
}
.form_wrap .frm_grp.error .error_message {
  display: block !important;
}
.form_footer {
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}
.ly_pop_toast {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  background-color: #ff2a8e;
  z-index: 1900;
}
.ly_pop_toast.success {
  background: forestgreen;
}
.ly_pop_toast > p {
  padding: 10px 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
}
/* btn */
.bt_wrap.w_full a {
  width: 100%;
}
.bt_wrap.w_full a span {
  width: 100%;
}
.bt_b50 {
  display: inline-block;
  height: 50px;
  border-radius: 10px;
  background-color: #5c59c3;
  border: 1px solid #5c59c3;
}
.bt_b50 span {
  display: block;
  width: 150px;
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  line-height: 48px;
  text-align: center;
}
.bt_b50.disable {
  pointer-events: none;
  border-color: #aaaaba;
  background-color: #aaaaba;
}
.bt_b50.disable span {
  color: #fff;
}
.loading-screen {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.9);
}
.progress {
  width: 104px;
  height: 15px;
  -webkit-mask: radial-gradient(circle closest-side, #000 94%, #0000) 0 0/25%
      100%,
    linear-gradient(#000 0 0) center/calc(100% - 10.1px) calc(100% - 10.1px)
      no-repeat;
  background: linear-gradient(#0c419a 0 0) left/0% 100% no-repeat #dbdcef;
  animation: progress-d7mi5a 2s infinite linear;
}
@keyframes progress-d7mi5a {
  100% {
    background-size: 100% 100%;
  }
}
.spinner {
  width: 10px;
  height: 10px;
  animation: spinner-o824ag 1s infinite linear;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -5px;
}
.spinner div {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #474bff;
  border-radius: 50%;
  animation: spinner-vse6n7 1.25s infinite ease;
}
.spinner div:nth-child(1) {
  --rotation: 90;
}
.spinner div:nth-child(2) {
  --rotation: 180;
}
.spinner div:nth-child(3) {
  --rotation: 270;
}
.spinner div:nth-child(4) {
  --rotation: 360;
}
@keyframes spinner-vse6n7 {
  0%,
  100% {
    transform: rotate(calc(var(--rotation) * 1deg)) translateY(0);
  }
  50% {
    transform: rotate(calc(var(--rotation) * 1deg)) translateY(300%);
  }
}
@keyframes spinner-o824ag {
  to {
    transform: rotate(360deg);
  }
}

.reset-password-container {
  min-width: 320px;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px 40px 40px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
h2 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  margin-bottom: 40px;
}
.error-message {
  color: #ff0000;
  margin-bottom: 10px;
}
.form-field {
  margin-bottom: 15px;
}
label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}
input[type='password'] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.submit-button {
  width: 100%;
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}
.submit-button:hover {
  background-color: #0069d9;
}
.submit-button:focus {
  outline: none;
}
